// BASE
// ****


// @font-face {
//   font-family: 'ChunkFiveRegular';
//   src: url('Chunkfive-webfont.eot');
//   src: url('Chunkfive-webfont.eot?#iefix') format('embedded-opentype'),
//   url('Chunkfive-webfont.woff') format('woff'),
//   url('Chunkfive-webfont.ttf') format('truetype'),
//   url('Chunkfive-webfont.svg#ChunkFiveRegular') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }


body {
  font-weight: 400;
  font-style: normal;
  color: @text-color; // #333;
  -webkit-font-smoothing: antialiased; // this is non-standard
  -moz-osx-font-smoothing: grayscale; // this is available in firefox from version 25 on (only firefox on mac)
}

div#top {
  position: absolute;
  top: 0px;
  visibility: hidden;
  z-index: -1;
}
div#bottom {
  position: relative;
  top: -50px;
}

.container {
  padding: 0;
  overflow: hidden;
}
@media (max-width: @screen-xs-max) {
  .container {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: @screen-phone) {
  .container {
    width: auto;
    padding-left: 10px; // 30px;
    padding-right: 10px; // 30px;
  }
}


.modal-content {
  display: none;
}

// *********************
// special layout issues
.scroll-page.position-top > div:first-child {
  padding-top: 160px;
}


// *******************
// mobile highlighting

div, a, a:link, a:hover, a:visited,
input, button, label
{
  -webkit-tap-highlight-color:rgba(200,200,200,0); /* alpha=0 disables default highlighting */
}


// ************
// type

// h1-h6 font-sizes see type.less
h1, h2, h3, h4, h5, h6 {
  margin: 0; // override bootstrap margin-top default
  text-rendering: auto; // do not use optimizelegibility
  font-family: @headings-font-family;
  margin-bottom: 12px;
}
// font-sizes are set in variables.less
h1, .h1 { font-weight: 300; } // ~30px
h2, .h2 { font-weight: 300; } // ~24px
h3, .h3 { font-weight: 400; } // ~18px
h4, .h4 { font-weight: 600; } // ~15px

h1 small, .h1 small { font-size: @font-size-base * 1.2; font-weight: 300; } // ~18px
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small { font-size: @font-size-base; font-weight: 300; }

h1.legend {
  padding-bottom: 6px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
}

// to use with h3 to add a thin style
.font-thin {
  font-weight: 300;
}

.formal-content.terms a {
  .hyphens(auto);
}

nobr {
  white-space: nowrap;
  .hyphens(none);
}

// blockquotes (e.g. used in dialogues)
blockquote {
  margin-bottom: 12px;
  border-left: 0 none; // reset bootstrap style

  p {
    font-size: @font-size-large;
    line-height: @line-height-large;
    font-weight: 300;
    font-style: italic;

    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      top: -80px;
      left: -40px;
      z-index: -1;

      content: '„';
      font-size: 100px;
      font-weight: 600;
      font-style: italic;
      color: @gray-lighter;
    }
  }
}


// undo browser specific styling
a:focus {
  //firefox:
  //outline: thin dotted rgb(51, 51, 51);
  //outline-offset: -2px;
  outline: 0 none;
}

// newsletter form
input[type=submit] {
  font-style: normal;
  font-weight: 300;
}
// input[type=submit],
// input[type=reset],
input,
textarea,
button {
  // -webkit-font-smoothing: antialiased; // this is non-standard
  -webkit-font-smoothing: inherit;
}

// custom button colors
@btn-custom-color:   #fff;
@btn-custom-bg:      @brand-custom;
@btn-custom-border:  darken(@btn-custom-bg, 5%);
.btn-custom {
  .button-variant(@btn-custom-color; @btn-custom-bg; @btn-custom-border);
}

@btn-app-color:   #fff;
@btn-app-bg:      @brand-app;
@btn-app-border:  darken(@btn-app-bg, 5%);
.btn-app {
  .button-variant(@btn-app-color; @btn-app-bg; @btn-app-border);
}

@btn-sec-color:   #fff;
@btn-sec-bg:      @brand-sec;
@btn-sec-border:  darken(@btn-sec-bg, 5%);
.btn-sec {
  .button-variant(@btn-sec-color; @btn-sec-bg; @btn-sec-border);
}

// ************************************
// glyphicons

.glyphicons {
  vertical-align: baseline;
  
  &.glyphicons-gaia {
    .rotate(90deg);
    &:before{
      content:"\E174";
    }
  }
}

// ************************************
// noscript

.noscript {
  visibility: visible;
  div.noscript-msg {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 100px;
    left: 50%;
    margin-left: -150px;
    width: 300px;
  }
}
@media screen and (min-width: @screen-xs-min) {
  .noscript {
    div.noscript-msg {
      margin-left: -200px;
      width: 400px;
    }
  }
}

// ************************************
// ************************************

