// kill the scroll on the body
.modal-bob-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  // width: 100%;
  // height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;

  .m-background {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background: #000000;
    .opacity(0.5);
    z-index: 1041;
  }

  .m-window {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 600px;
    margin-left: -300px;
    margin-bottom: 20px;
    z-index: 1050;

    // taken from bootstrap
    border: 1px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
    border: 1px solid @modal-content-border-color;
    border-radius: @border-radius-large;
    .box-shadow(0 3px 9px rgba(0,0,0,.5));
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: none;
    background: #ffffff;

    .m-close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 5px 10px;
      cursor: pointer;

      i {
        background-color: #ffffff;
      }
    }

  }
}

// make the modal scrollable
.modal-bob-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-msg {
  display: none;
  position: fixed;
  top: 80px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  z-index: 1080;
  // Remove focus outline from opened modal
  outline: none;
  border: 1px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 1px solid @modal-content-border-color;
  border-radius: @border-radius-large;
  .box-shadow(0 3px 9px rgba(0,0,0,.5));
  cursor: pointer;

  .msg-content {
    display: none;
    .opacity(0.95);
    border-radius: @border-radius-large;
    padding: 30px 30px 30px 25px;
    .clearfix();

    i {
      float: left;
      width: 40px;
      font-size: 24px;
    }
    span {
      display: block;
      margin-left: 40px;
    }
  }

  .msg-success {
    background-color: #fff;
    i {
      color: #4cae4c; // #3c763d; // #4cae4c; // #82D182;
    }
  }

  .msg-error {
    background-color: #fff;
    i {
      color: #d43f3a; // #a94442; // #d43f3a; // #E8AEB7;
    }
  }

  .msg-warning {
    background-color: #fff;
    i {
      color: #d43f3a; // #ffc107;
    }
  }

  .msg-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
  }

}


.modal-form-context {

}

.modal-layer-context {
  .m-window {
    top: 20px;
    width: 800px;
    margin-left: -400px;
  }

  .m-content {
    padding: 20px;
  }
}

@media (max-width: @screen-xs-max) { // @screen-tablet

  .modal {
    .m-window {
      width: 400px;
      margin-left: -200px;
    }
  }
  .modal-layer-context {
    .m-window {
      top: 20px;
      width: 480px;
      margin-left: -240px;
    }
  }
  .modal-msg {
    top: 20px;
  }
}

@media (max-width: (@screen-xs-min - 1)) { // @screen-phone

  .modal {
    .m-window {
      top: 0;
      left: 0;
      width: 100%;
      margin-left: 0;
      border-radius: 0;
    }
  }
  .modal-msg {
    top: 0;
    left: 0;
    width: 100%;
    margin-left: 0;
    border-radius: 0;
    .msg-content {
      border-radius: 0;
    }
  }
}
