@font-face {
    font-family: "Gotham SSm A";
    font-style: normal;
    font-weight: 200;
    src: url("/fonts/GothamSSm/woff2/GothamSSm-XLight_Web.woff2") format("woff2"),
    url("/fonts/GothamSSm/woff/GothamSSm-XLight_Web.woff") format("woff");
}

// @font-face {
//     font-family: "Gotham SSm A";
//     font-style: italic;
//     font-weight: 200;
//     src: url("/fonts/GothamSSm/woff2/GothamSSm-XLightItalic_Web.woff2") format("woff2"),
//     url("/fonts/GothamSSm/woff/GothamSSm-XLightItalic_Web.woff") format("woff");
// }

@font-face {
    font-family: "Gotham SSm A";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/GothamSSm/woff2/GothamSSm-Medium_Web.woff2") format("woff2"),
    url("/fonts/GothamSSm/woff/GothamSSm-Medium_Web.woff") format("woff");
}
