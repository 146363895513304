// jumbotron
// ---------

.jumbotron {
	height: 675px;
	background-color: @brand-hero;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: @screen-md-min) {
    h1 {
      font-size: (@font-size-base * 3.5);
    }
    p {
      font-size: ceil(@font-size-base * 1.3);
    }
  }
  @media screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    height: 600px;
    h1 {
      font-size: (@font-size-base * 2.5);
    }
    p {
      font-size: ceil(@font-size-base * 1.1);
    }
  }
  @media screen and (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
    height: 500px;
    h1 {
      font-size: (@font-size-base * 2.0);
    }
    p {
      font-size: ceil(@font-size-base * 1.1);
    }
  }
	@media screen and (max-width: @screen-phone) and (min-width: 360px) {
  	height: 400px; // 400px;
    h1 {
      font-size: ceil(@font-size-base * 1.3);
    }
    p {
  		font-size: ceil(@font-size-base * 0.9);
  	}
	}
  @media screen and (max-width: 359px) {
    height: 320px; // 400px;
    h1 {
      font-size: ceil(@font-size-base * 1.0);
    }
    p {
      font-size: ceil(@font-size-base * 0.7);
    }
  }
}
