// NAV
// ***

nav {

  .container {
    overflow: visible;
  }

  .navbar-logo {
    max-width: 165px;
    overflow: hidden;
    padding-left: 15px;
    a {
      background-position: 0 0;
      background-repeat: no-repeat;
      height: @navbar-height;
      display: table-cell;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  // only mobile (part of .navbar-toggle)
  .navbar-active-item {
    display: block;
    position: absolute;
    overflow: hidden;
    right: 40px;
    bottom: 4px;
    color: white;

    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: @font-size-xsmall;
    // .opacity(0.7);

    i:before {
      padding: 0 5px 0 15px;
    }
    i.glyphicons-gaia:before {
      padding: 0 2px 0 2px; // wacky hacky cause of rotated play icon
    }
  }
  .navbar-collapse {
    box-shadow: none;
  }

  // fix white background on hover (bootstrap)
  // and hide button border
  &.navbar-default {
    .navbar-toggle:hover,
    .navbar-toggle:focus {
      background-color: transparent;
    }
    .navbar-toggle {
      border-color: transparent;
    }
  }

  .navbar-nav {
    a, a:hover,
    li > a, a:hover {
      .opacity(1);
    }
    li > a {
      .opacity(0.7);
    }

    li > a > i:before {
      padding: 0 5px 0 5px;
    }

    // li.open > a {
    //   background-color: transparent;
    //   color: #fff;
    // }
  }

  // do not show 
  .navbar-nav li > a:focus,
  .navbar-toggle {
    outline: none 0;
  }

  // ie11 mobile
  button.navbar-toggle {
    overflow: visible;
  }
}

@media (min-width: @screen-tablet) {
  .navbar-logo {
    margin-left: -15px;
  }

  .page_1 .navbar li > a.page_1,
  .page_2 .navbar li > a.page_2,
  .page_3 .navbar li > a.page_3,
  .page_4 .navbar li > a.page_4 {
    background-color: @navbar-default-link-active-bg;
    .opacity(1);
  }

  .navbar-nav .dropdown-locale span {
    display: none;
  }
}

