// paragraph


.paragraphs .paragraph .container {
  padding-top: 100px;
  padding-bottom: 100px;
}
.paragraphs {
	margin-bottom: 100px;
}
@media (max-width: @screen-tablet) {
  .paragraphs .paragraph .container {
	  padding-top: 100px;
	  padding-bottom: 60px;
  }
  .paragraphs {
  	margin-bottom: 100px;
  }
}
@media (max-width: @screen-phone) {
  .paragraphs .paragraph .container {
    padding-top: 100px;
    padding-bottom: 0;
    border-top: 1px solid @gray-lighter;
  }
  .paragraphs {
  	margin-bottom: 100px;
  }
}


.paragraph-img {

	margin-bottom: 50px;

	img {
		border-radius: @border-radius-large;
	}

	.img-default {
		// float: right;
		display: block;
		width: 540px; // 520px;
		height: auto; // 504px; // 390px;
		margin: 0 auto;
	}
	.img-tablet {
		display: none;
		width: 378px; // 360px;
		height: auto; // 557px; // 500px;
		margin: 0 auto;
	}
	.img-phone {
		display: none;
		width: 260px; // 300px;
		height: auto; // 536px; // 530px;
		margin: 0 auto;
	}

	// .img-right {
	// 	float: right;
	// }
	// .img-left {
	// 	float: left;
	// }
}

@media (min-width: @screen-lg-min) {

	.paragraph:nth-child(2n+1) {
		.paragraph-img {
	    // left: 41.66666666666667%; // .col-lg-push-5
    	left: percentage((5 / @grid-columns));

			.img-align {
				float: right;
			}
		}

		.paragraph-copy {
	  	// right: 58.333333333333336%; // .col-lg-pull-7
    	right: percentage((7 / @grid-columns));
		}
	}
	.paragraph:nth-child(2n) {
		.img-align {
			float: left;
		}
	}

}

@media (max-width: @screen-lg-desktop) {

.paragraph-img {

	.img-default {
		display: block;
		// float: none;
		// width: 520px; // 80%;
	}
	.img-tablet {
		display: none;
	}
	.img-phone {
		display: none;
	}

	// .img-right,
	// .img-left,
	.img-align {
		float: none;
	}
}

}

// @media (max-width: @screen-tablet) {
@media (max-width: 1024px) {

.paragraph-img {

	.img-default {
		display: none;
	}
	.img-tablet {
		display: block;
		// width: 80%;
	}
	.img-phone {
		display: none;
	}

	// .img-right,
	// .img-left,
	.img-align {
		float: none;
	}
}

}

@media (max-width: @screen-phone) {

.paragraph-img {

	.img-default {
		display: none;
	}
	.img-tablet {
		display: none;
	}
	.img-phone {
		display: block;
		// width: 100%;
	}

	// .img-right,
	// .img-left,
	.img-align {
		float: none;
	}
}

}

